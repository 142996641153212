import { Badge, Button, Card, Carousel, Col, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { CurrencyService } from "../../Services/Currency"
import { NumericFormat } from "react-number-format"
import { IListProduct } from "../../Types"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { ApiError } from "../../Services/BaseApi"
import { ReactNode, useMemo } from "react"
import Loading from "../Loading"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMarkListProductDoneMutation, useUpdateListProductMutation, useDeleteListProductMutation } from "../../Store/ListProduct/ListProduct.service"
import { useGetStoreQuery } from "../../Store/Store/Store.service"
import { useTranslation } from "react-i18next"

function ProductListItem({
    product,
    onEdit,
}: {
    product: IListProduct
    onEdit: (product: IListProduct) => void
}) {

    const { cardBgStyle, textClassName, titleStyle } = useMemo(() => {
        let cardBgStyle: React.CSSProperties | undefined = { backgroundColor: '#dddddd' }
        let textClassName = undefined
        let titleStyle = undefined
        if (product.status === "COMPLETE") {
            cardBgStyle = undefined
            textClassName = 'text-muted'
            titleStyle = { textDecoration: 'line-through' }
        }
        return { cardBgStyle, textClassName, titleStyle }
    }, [product.status])

    return (
        <Row key={product._id} className="mb-3">
            <Col>
                <Card
                    style={cardBgStyle}
                >
                    {product.imageUrls?.length ? <Carousel interval={null}>
                        {product.imageUrls.map((imageUrl, idx) => <Carousel.Item key={idx}>
                            <Card.Img src={imageUrl} />
                        </Carousel.Item>)}
                    </Carousel> : null}
                    <Card.Body>
                        <Row>
                            <Col className={textClassName}>
                                <div className="fw-bold" style={titleStyle}>{product.title}</div>
                                {product.storeId ? <ProductStore product={product} /> : null}
                                {product.description ? <div className="small mb-3">{product.description}</div> : null}
                            </Col>
                            {product.qty ? <ProductQty product={product} /> : null}
                        </Row>
                        <ProductAttr product={product} />
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col>
                                <ProductMarkComplete product={product} />
                            </Col>
                            <Col xs="auto"><Icon.Pencil onClick={() => onEdit(product)} className="text-secondary" /></Col>
                            <Col xs="auto"><ProductDeleteButton product={product} /></Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
}

export default ProductListItem

function ProductMarkComplete({ product }: { product: IListProduct }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [markDone, markDoneResult] = useMarkListProductDoneMutation()

    const onMark = async (product: IListProduct) => {
        try {
            await markDone({
                eventId: product.eventId,
                listId: product.listId,
                ids: [product._id],
                isDone: product.status !== "COMPLETE",
            })
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const variant = useMemo(() => {
        return product.status === "PENDING" ?
            'success' :
            'secondary'
    }, [product.status])

    const label = useMemo(() => {
        let label: string | ReactNode = product.status === "PENDING" ?
            t('doneButtonLabel') :
            t('undoneButtonLabel')
        if (markDoneResult.isLoading) {
            label = <Loading />
        }
        return label
    }, [product.status, markDoneResult.isLoading, t])

    return (
        <Button
            variant={variant}
            size="sm"
            onClick={() => onMark(product)}
        >
            {label}
        </Button>
    )
}

function ProductQty({ product }: { product: IListProduct }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateProduct, updateProductResult] = useUpdateListProductMutation()

    const onQtyChange = async (product: IListProduct, qty: number) => {
        if (updateProductResult.isLoading) {
            return
        }
        try {
            await updateProduct({
                eventId: product.eventId,
                listId: product.listId,
                productId: product._id,
                qty,
            }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const badgeVariant = useMemo(() => {
        let badgeVariant = 'primary'
        if (product.status === "COMPLETE") {
            badgeVariant = 'secondary'
        }
        return badgeVariant
    }, [product.status])

    return (
        <Col xs="auto">
            {product.status === 'PENDING' ? <Row>
                <Col className="text-center">
                    <Icon.CaretUp
                        className="text-secondary"
                        onClick={() => onQtyChange(product, product.qty ? product.qty + 1 : 1)}
                    />
                </Col>
            </Row> : null}
            <Row>
                <Col className="text-center">
                    <Badge
                        pill
                        bg={badgeVariant}
                    >
                        <NumericFormat
                            value={product.qty}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                        />
                    </Badge>
                </Col>
            </Row>
            {product.status === 'PENDING' && undefined !== product.qty && product.qty > 1 ? <Row>
                <Col className="text-center">
                    <Icon.CaretDown
                        className="text-secondary"
                        onClick={() => onQtyChange(product, product.qty && product.qty > 1 ? product.qty - 1 : 1)}
                    />
                </Col>
            </Row> : null}
        </Col>
    )
}

function ProductAttr({ product }: { product: IListProduct }) {

    const { t } = useTranslation()
    const weight = useMemo(() => {
        let weight = ''
        if (product.weightGr) {
            weight = `${product.weightGr}`
        }
        return weight
    }, [product.weightGr])

    const symbol = useMemo(() => {
        let symbol = '$'
        if (product.currency) {
            const currency = CurrencyService.getCurrency(product.currency)
            if (currency) {
                symbol = currency.symbol
            }
        }
        return symbol
    }, [product.currency])

    const unitPrice = useMemo(() => {
        if (!product.unitPrice) {
            return 0
        }
        return product.qty ? product.unitPrice * product.qty : product.unitPrice
    }, [product.unitPrice, product.qty])

    return (
        <Row>
            {product.unitPrice ? <Col xs="auto">
                <Row>
                    <Col className="small">{t('priceLabel')}</Col>
                </Row>
                <Row>
                    <Col className="fs-4">
                        <NumericFormat
                            value={unitPrice}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={symbol}
                        />
                    </Col>
                </Row>
            </Col> : null}
            {weight ? <Col xs="auto">
                <Row>
                    <Col className="small">{t('weightLabel')}</Col>
                </Row>
                <Row>
                    <Col className="fs-3">
                        <NumericFormat
                            value={weight}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                            suffix={t('grLabel')}
                        />
                    </Col>
                </Row>
            </Col> : null}
        </Row>
    )
}

function ProductDeleteButton({ product }: { product: IListProduct }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [deleteListProduct, deleteListProductResult] = useDeleteListProductMutation()

    const onDelete = async (product: IListProduct) => {
        if (deleteListProductResult.isLoading) {
            return
        }
        const confirm = window.confirm(t('deleteProductFromShoppingListConfirmationText', {productTitle: product.title}))
        if (confirm) {
            try {
                await deleteListProduct({
                    eventId: product.eventId,
                    listId: product.listId,
                    productId: product._id,
                })
                dispatch(setMessage(t('productRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    return (
        <Icon.Trash
            onClick={() => onDelete(product)}
            className={deleteListProductResult.isLoading ? "text-muted" : "text-danger"}
        />
    )
}

function ProductStore({ product }: { product: IListProduct }) {

    const args = product.storeId ? { storeId: product.storeId, productId: product._id } : skipToken
    const storeQuery = useGetStoreQuery(args)

    return (
        <div className="small mb-3">
            <div>{storeQuery.data?.name}</div>
            {storeQuery.data?.address ? <div><a href={`https://maps.google.com/?q=${storeQuery.data.address}`} target="_blank" rel="noreferrer">{storeQuery.data.address}</a></div> : null}
        </div>
    )
}