import { IEvent, IReceipt, IShoppingList, Member, Value } from "../../../Types"
import { Button, Card, ListGroup } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import EditText from "../../EditResource/EditText"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"
import EditMembers from "../../EditResource/EditMembers"
import EditDateTime from "../../EditResource/EditDateTime"
import EditList from "../../EditResource/EditList"
import { useMemo } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import EditImage from "../../EditResource/EditImage"
import { useAddShoppingListInvitationMutation, useDeleteListReceiptMutation, useDeleteShoppingListInvitationMutation, useDeleteShoppingListMutation, useUpdateShoppingListMutation } from "../../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import EditReceipts from "../../EditResource/EditReceipts"

function EditShoppingListPage({ event, list }: { event: IEvent, list: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [addShoppingListInvitation] = useAddShoppingListInvitationMutation()
    const [deleteShoppingListInvitation] = useDeleteShoppingListInvitationMutation()
    const [deleteShoppingList, deleteShoppingListResult] = useDeleteShoppingListMutation()
    const [deleteListReceipt, deleteListReceiptResult] = useDeleteListReceiptMutation()

    const { user } = useAuth0();

    const members = useMemo(() => {
        let members: Member[] = []
        if (list.members?.length) {
            members = list.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }
        if (user) {
            members = [{
                userId: user.sub ?? '',
                email: user.email ?? '',
                name: user.name ?? '',
                picture: user.picture,
                isMe: true,
            }, ...members]
        }
        return members
    }, [list.members, user])

    const handleUpdate = async (value: string | number | boolean | Value | IReceipt, key: "title" | "description" | "scheduleAt" | "assigneeUserId" | "isComplete" | "imageUrl" | "receipt") => {
        if (!event) {
            return
        }
        try {
            const q: { [index: string]: string | number | boolean | Value | IReceipt } = {}
            q[key] = value
            await updateShoppingList({ eventId: event._id, listId: list._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const unshare = async (email: string) => {
        if (!list) {
            return
        }
        try {
            await deleteShoppingListInvitation({ listId: list._id, email }).unwrap()
            dispatch(setMessage(t('invitationRemoveNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const invite = async (email: string, userId?: string) => {
        if (!email) {
            return
        }
        try {
            await addShoppingListInvitation({ listId: list._id, email, userId }).unwrap()
            dispatch(setMessage(t('invitationSentNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const getDisplayLabel = (userId: string) => {
        if (!members?.length) {
            return ''
        }
        let member: Member | null = null
        for (const memberObj of members) {
            if (memberObj.userId === userId) {
                member = memberObj
                break
            }
        }
        if (member) {
            return <>
                <div>{member.isMe ? 'You' : member.name}</div>
                {!member.isMe ? <div className="small">{member.email}</div> : null}
            </>
        }
        return ''
    }

    const getDisplayItem = (member: Member) => {
        return (
            <>
                <div>{member.isMe ? 'You' : member.name}</div>
                {!member.isMe ? <div className="small">{member.email}</div> : null}
            </>
        )
    }

    const completeList = (isComplete: boolean) => {
        handleUpdate(isComplete, "isComplete")
    }

    const onDelete = async () => {
        if (deleteShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Delete shopping list "${list.title}"?`)
            if (confirm) {
                await deleteShoppingList({ eventId: list.eventId, listId: list._id }).unwrap()
                dispatch(setMessage(t('shoppingListRemovedNotification')))
                navigate(`/app/events/${list.eventId}`)
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const shareMobile = async (token: string) => {
        if (navigator.share) {
            await navigator.share({
                title: event.title,
                text: `I invite you to join shopping list.`,
                url: `/app/list-invitations/${token}`
            })
        }
    }

    const onDeleteReceipt = async (receiptIndex: number) => {
        const confirm = window.confirm(`Delete receipt?`)
        if (confirm) {
            try {
                await deleteListReceipt({
                    eventId: list.eventId,
                    listId: list._id,
                    receiptIndex,
                }).unwrap()
                dispatch(setMessage(t('receiptRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    return (
        <Card>
            <Card.Img src={list.imageUrl} />
            <ListGroup>
                <ListGroup.Item>
                    <EditText
                        icon={<Icon.Fonts />}
                        disabled={updateShoppingListResult.isLoading}
                        defaultValue={list.title}
                        placeholder={t('shoppingListTitleInputPlaceholder')}
                        handleUpdate={text => handleUpdate(text, "title")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditImage
                        icon={<Icon.Image />}
                        disabled={updateShoppingListResult.isLoading}
                        defaultValue={list.imageUrl}
                        placeholder={t('uploadImagePlaceholder')}
                        handleUpdate={imageUrl => handleUpdate(imageUrl, "imageUrl")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditTextarea
                        icon={<Icon.CardText />}
                        disabled={updateShoppingListResult.isLoading}
                        defaultValue={list.description}
                        placeholder={t('addDescriptionPlaceholder')}
                        handleUpdate={text => handleUpdate(text, "description")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditDateTime
                        icon={<Icon.Clock />}
                        disabled={updateShoppingListResult.isLoading}
                        defaultValue={list.scheduleAt}
                        placeholder={t('addScheduleInputPlaceholder')}
                        handleUpdate={value => handleUpdate(value, "scheduleAt")}
                    />
                </ListGroup.Item>
                {members?.length ? <ListGroup.Item>
                    <EditList<Member>
                        icon={<Icon.Person />}
                        disabled={updateShoppingListResult.isLoading}
                        defaultValue={list.assigneeUserId}
                        placeholder={t('assignMemberLabel')}
                        handleUpdate={assigneeUserId => handleUpdate(assigneeUserId, "assigneeUserId")}
                        objects={members}
                        primKey="userId"
                        title={t('selectMemberLabel')}
                        isMandatory={false}
                        getDisplayLabel={getDisplayLabel}
                        getDisplayItem={getDisplayItem}
                    />
                </ListGroup.Item> : null}
                <ListGroup.Item>
                    <EditMembers
                        defaultValue={list.members}
                        unshare={unshare}
                        invite={invite}
                        shareMobile={shareMobile}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditReceipts
                        icon={<Icon.CardText />}
                        disabled={updateShoppingListResult.isLoading}
                        receipts={list.receipts}
                        placeholder={t('uploadReceiptsPlaceholder')}
                        handleUpdate={receipt => handleUpdate(receipt, "receipt")}
                        isLoading={deleteListReceiptResult.isLoading}
                        onDeleteReceipt={onDeleteReceipt}
                    />
                </ListGroup.Item>
                <ListGroup.Item className="text-center">
                    {list.isComplete ? <Button
                        variant="link"
                        onClick={() => completeList(false)}
                    >{t('markListIncompleteText')}</Button> : <Button
                        variant="link"
                        onClick={() => completeList(true)}
                    >{t('markListCompleteText')}</Button>}
                </ListGroup.Item>
            </ListGroup>

            <div className="d-grid gap-2 mt-3">
                <Button
                    type='submit'
                    variant="danger"
                    disabled={deleteShoppingListResult.isLoading}
                    onClick={onDelete}
                ><Icon.Trash /> {t('deleteButtonLabel')}</Button>
            </div>
        </Card>
    )
}

export default EditShoppingListPage