import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Button, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import * as Icon from 'react-bootstrap-icons'
import EventUserAvatars from "../EventUserAvatars"
import ShoppingListItem from "../ShoppingListItem"
import { useMemo } from "react"
import { Member } from "../../Types"
import TitleButton from "../TitleButton"
import { useListShoppingListsQuery } from "../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"

function Event() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ? { eventId } : skipToken
    const eventQuery = useGetEventQuery(args)

    const listArgs = eventId ?? skipToken
    const listsQuery = useListShoppingListsQuery(listArgs)

    const members = useMemo(() => {
        if (eventQuery.isLoading || !eventQuery.isSuccess || !eventQuery.data?.members?.length) {
            return []
        }
        const list = eventQuery.data
        const members = list.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [eventQuery.isLoading, eventQuery.isSuccess, eventQuery.data])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{eventQuery.data?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {eventQuery.data?.title ? <Row className="mb-3">
                <Col>
                    <TitleButton
                        title={eventQuery.data?.title}
                        buttonText={<><Icon.Pencil /> {t('editButtonLabel')}</>}
                        onClick={() => navigate(`/app/events-edit/${eventId}?r=/app/events/${eventId}`)}
                    />
                </Col>
            </Row> : null}
            {members.length ? <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('membersTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.People />
                    </OverlayTrigger>
                </Col>
                <Col><EventUserAvatars users={members.map(member => member)} /></Col>
            </Row> : null}
            {eventQuery.data?.description ? <Row className="mb-3">
                <Col className="small">
                    {eventQuery.data?.description}
                </Col>
            </Row> : null}
            {listsQuery.data?.length ? <Row className="mb-3">
                <Col>
                    <TitleButton
                        title={t('shoppingListsTitle')}
                        buttonText={<><Icon.Plus /> {t('addShoppingListButtonLabel')}</>}
                        onClick={() => navigate(`/app/events/${eventId}/lists-add`)}
                    />
                </Col>
            </Row> : null}
            {eventId && listsQuery.data?.length ? <Row>
                <Col>
                    {listsQuery.data?.map(list => <ShoppingListItem
                        key={list._id}
                        list={list}
                        isShared={false}
                        canEdit={true}
                    />)}
                </Col>
            </Row> : <Row>
                <Col>
                    <Card>
                        <Card.Body className="text-center">
                            <Card.Text>{t('addFirstList')}</Card.Text>
                            <Button
                                variant="primary"
                                onClick={() => navigate(`/app/events/${eventId}/lists-add`)}
                            ><Icon.Plus /> {t('addShoppingListButtonLabel')}</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>}
        </Container>
    )
}

export default Event