import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { useListRecentShoppingListsQuery } from "../../Store/ShoppingList/ShoppingList.service"
import ShoppingListItem from "../ShoppingListItem"
import { useTranslation } from "react-i18next"
import { useListEventsQuery } from "../../Store/Event/Event.service"
import { useMemo } from "react"
import EventListItem from "../EventListItem"
import * as Icon from 'react-bootstrap-icons'
import { useNavigate } from "react-router-dom"

function Main() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const eventsQuery = useListEventsQuery()
    const listsQuery = useListRecentShoppingListsQuery()

    const view = useMemo(() => {
        let view = <Row>
            <Col>
                <Card>
                    <Card.Body className="text-center">
                        <Card.Text>{t('addFirstEvent')}</Card.Text>
                        <Button
                            variant="primary"
                            onClick={() => navigate('/app/events-add')}
                        ><Icon.Plus /> {t('addEventButtonLabel')}</Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        if (!eventsQuery.isLoading && eventsQuery.isSuccess && eventsQuery.data?.length) {
            view = <Row>
                <Col>
                    <div className="fw-bold mb-3">{t('recentShoppingListsTitle')}</div>
                    {eventsQuery.data.map(event => <EventListItem
                        key={event._id}
                        event={event}
                        canEdit={true}
                        isShared={false}
                    />)}
                </Col>
            </Row>
        }
        if (!listsQuery.isLoading && listsQuery.isSuccess && listsQuery.data?.length) {
            view = <Row>
                <Col>
                    <div className="fw-bold mb-3">{t('recentShoppingListsTitle')}</div>
                    {listsQuery.data?.map(list => <ShoppingListItem
                        key={list._id}
                        list={list}
                        isShared={false}
                        canEdit={true}
                    />)}
                </Col>
            </Row>
        }
        return view
    }, [
        eventsQuery.isLoading,
        eventsQuery.isSuccess,
        eventsQuery.data,
        listsQuery.isLoading,
        listsQuery.isSuccess,
        listsQuery.data,
        t,
    ])

    return (
        <Container>
            {view}
        </Container>
    )
}

export default Main