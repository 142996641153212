import { useNavigate } from "react-router-dom"
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import EventUserAvatars from "../EventUserAvatars"
import * as Icon from 'react-bootstrap-icons'
import { IEvent, Member } from "../../Types"
import { useMemo } from "react"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { useDeleteEventMutation } from "../../Store/Event/Event.service"
import { useTranslation } from "react-i18next"

function EventListItem({ event, canEdit, isShared }: { event: IEvent, canEdit: boolean, isShared: boolean }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [deleteEvent, deleteEventResult] = useDeleteEventMutation()

    const members = useMemo(() => {
        let members: Member[] = []
        if (event.members?.length) {
            members = event.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [event.members])

    const onDelete = async (event: IEvent) => {
        if (deleteEventResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(t('deleteEventConfirmationText', { eventTitle: event.title }))
            if (confirm) {
                await deleteEvent({ eventId: event._id }).unwrap()
                dispatch(setMessage(t('deleteEventNotification')))
                navigate(`/app/events`)
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Card
            className="mb-3"
        >
            {event.imageUrl ? <Card.Img
                onClick={() => navigate(`/app/events/${event._id}`)}
                src={event.imageUrl}
            /> : null}
            <Card.Body>
                <Card.Title
                    onClick={() => navigate(`/app/events/${event._id}`)}
                >{event.title}</Card.Title>
                {members.length ? <Row className="mb-1">
                    <Col xs="auto">
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={props => (
                                <Tooltip id="button-tooltip" {...props}>
                                    {t('membersTitle')}
                                </Tooltip>
                            )}
                        >
                            <Icon.People />
                        </OverlayTrigger>
                    </Col>
                    <Col>
                        <EventUserAvatars users={members} />
                    </Col>
                </Row> : null}
                {event.description ? <Row>
                    <Col className="small">{event.description}</Col>
                </Row> : null}
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col></Col>
                    {canEdit ? <Col xs="auto" className="text-end">
                        <Icon.Pencil
                            onClick={() => {
                                navigate(`/app/events-edit/${event._id}?r=/app/events`)
                            }}
                        />
                    </Col> : null}
                    {canEdit ? <Col xs="auto" className="text-end">
                        <Icon.Trash
                            className="text-danger"
                            onClick={() => onDelete(event)}
                        />
                    </Col> : null}
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default EventListItem