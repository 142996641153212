import { useTranslation } from "react-i18next"
import { Breadcrumb, Button, Card, Carousel, Col, Container, ListGroup, Row } from "react-bootstrap"
import EditText from "../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import EditTextarea from "../EditResource/EditTextarea"
import Loading from "../Loading"
import { useDispatch } from "react-redux"
import { ApiError } from "../../Services/BaseApi"
import { useMemo } from "react"
import { CurrencyService } from "../../Services/Currency"
import { useDeleteProductImageMutation, useDeleteProductMutation, useGetProductQuery, useUpdateProductMutation } from "../../Store/Product/Product.service"
import { useListStoresQuery } from "../../Store/Store/Store.service"
import { currencyCodes, IStore, currencyCodesType, Currency } from "../../Types"
import EditImages from "../EditResource/EditImages"
import EditList from "../EditResource/EditList"
import EditNumber from "../EditResource/EditNumber"
import { AppDispatch } from "../../Store/Store"
import { skipToken } from "@reduxjs/toolkit/query"
import { useNavigate, useParams } from "react-router-dom"
import { setMessage } from "../../Store/Toast/Toast.slice"

function ProductEdit() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { productId } = useParams()

    const arg = productId ?? skipToken
    const productQuery = useGetProductQuery(arg)

    const [updateProduct, updateProductResult] = useUpdateProductMutation()
    const [deleteProduct, deleteProductResult] = useDeleteProductMutation()
    const [deleteProductImage, deleteProductImageResult] = useDeleteProductImageMutation()

    const query = useListStoresQuery('')

    const currencies = useMemo(() => {
        const currencies: Currency[] = []
        for (const code of currencyCodes) {
            const currency = CurrencyService.getCurrency(code)
            if (currency) {
                currencies.push(currency)
            }
        }
        return currencies
    }, [])

    const handleUpdate = async (value: string | number, key: "title" | "description" | "unitPrice" | "weightGr" | "currency" | "storeId" | "imageUrl") => {
        if (!productQuery.data) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateProduct({ productId: productQuery.data._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const getDisplayLabelStore = (storeId: string) => {
        if (!query.data) {
            return ''
        }
        let store: IStore | null = null
        for (const storeItem of query.data) {
            if (storeItem._id === storeId) {
                store = storeItem
                break
            }
        }
        if (store) {
            return <>
                <div>{store.name}</div>
                <div className="small">{store.address}</div>
                <div className="small">{store.description}</div>
            </>
        }
        return ''
    }

    const getDisplayItemStore = (store: IStore) => <>
        <div>{store.name}</div>
        <div className="small">{store.address}</div>
        <div className="small">{store.description}</div>
    </>

    const getDisplayLabelCurrency = (code: currencyCodesType) => {
        const currency = CurrencyService.getCurrency(code)
        if (currency) {
            return <>
                {currency.name} {currency.symbol}
            </>
        }
        return ''
    }

    const getDisplayItemCurrency = (currency: Currency) => {
        if (!currency) {
            return null
        }
        return (
            <>
                <div>{currency.symbol}</div>
                <div className="small">{currency.name}</div>
            </>
        )
    }

    const onFilterCurrency = (search: string, objects: Currency[]) => {
        return objects.filter(currency => {
            if (currency.code.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (currency.name && currency.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        })
    }

    const onFilterStore = (search: string, objects: IStore[]) => {
        return objects.filter(store => {
            if (store.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.description && store.description.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.address && store.address.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        })
    }

    const onDelete = async () => {
        const product = productQuery.data
        if (!product) {
            return
        }
        const confirm = window.confirm(`Delete product ${product.title}?`)
        if (confirm) {
            if (!product) {
                return
            }
            try {
                await deleteProduct(product._id).unwrap()
                navigate(`/app/products`)
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const onDeleteImage = async (imageIndex: number) => {
        const product = productQuery.data
        if (!product) {
            return
        }
        const confirm = window.confirm(`Delete product image?`)
        if (confirm) {
            try {
                await deleteProductImage({
                    productId: product._id,
                    imageIndex,
                }).unwrap()
                dispatch(setMessage(t('imageRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const currencyIcon = useMemo(() => {
        let icon = <Icon.Icon123 />
        if (!productQuery.isLoading && productQuery.isSuccess && productQuery.data) {
            const symbol = CurrencyService.getCurrency(productQuery.data.currency as currencyCodesType)?.symbol
            if (symbol) {
                icon = <>{symbol}</>
            }
        }
        return icon
    }, [
        productQuery.isLoading,
        productQuery.isSuccess,
        productQuery.data,
    ])

    const view = useMemo(() => {
        let view = <Row>
            <Col><Loading /></Col>
        </Row>
        if (!productQuery.isLoading && productQuery.isSuccess && productQuery.data) {
            const product = productQuery.data
            view = <Row>
                <Col>
                    {product.imageUrls?.length ? <Carousel>
                        {product.imageUrls.map((imageUrl, idx) => <Carousel.Item key={idx}>
                            <Card.Img src={imageUrl} />
                        </Carousel.Item>)}
                    </Carousel> : null}
                    <ListGroup>
                        <ListGroup.Item>
                            <EditText
                                icon={<Icon.Fonts />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.title}
                                placeholder={t('listProductTitleValuePlaceholder')}
                                handleUpdate={text => handleUpdate(text, "title")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditImages
                                icon={<Icon.Image />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                imageUrls={product.imageUrls}
                                placeholder={t('uploadImagePlaceholder')}
                                handleUpdate={imageUrl => handleUpdate(imageUrl, "imageUrl")}
                                isLoading={deleteProductImageResult.isLoading}
                                onDeleteImage={onDeleteImage}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditTextarea
                                icon={<Icon.CardText />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.description}
                                placeholder={t('addDescriptionPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "description")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditList<Currency>
                                icon={<Icon.Cash />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.currency}
                                placeholder={t('addCurrencyInputPlaceholder')}
                                handleUpdate={code => handleUpdate(code, "currency")}
                                objects={currencies}
                                primKey="code"
                                title={t('selectCurrencyInputPlaceholder')}
                                isMandatory={false}
                                getDisplayLabel={code => getDisplayLabelCurrency(code as currencyCodesType)}
                                getDisplayItem={getDisplayItemCurrency}
                                onFilter={onFilterCurrency}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditNumber
                                icon={currencyIcon}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.unitPrice}
                                placeholder={t('addUnitPriceInputPlaceholder')}
                                handleUpdate={num => handleUpdate(num, "unitPrice")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditNumber
                                icon={<Icon.Stack />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.weightGr}
                                placeholder={t('addWeightInputPlaceholder')}
                                handleUpdate={num => handleUpdate(num, "weightGr")}
                                right={<>gr</>}
                            />
                        </ListGroup.Item>
                        {query.data ? <ListGroup.Item>
                            <EditList<IStore>
                                icon={<Icon.Shop />}
                                disabled={updateProductResult.isLoading || deleteProductResult.isLoading}
                                defaultValue={product.storeId}
                                placeholder={t('addStoreInputPlaceholder')}
                                handleUpdate={storeId => handleUpdate(storeId, "storeId")}
                                objects={query.data}
                                primKey="_id"
                                title={t('selectStoreInputPlaceholder')}
                                isMandatory={false}
                                getDisplayLabel={getDisplayLabelStore}
                                getDisplayItem={getDisplayItemStore}
                                onFilter={onFilterStore}
                            />
                        </ListGroup.Item> : null}
                    </ListGroup>

                    <div className="d-grid gap-2 mt-3">
                        <Button
                            type='submit'
                            variant="danger"
                            disabled={deleteProductResult.isLoading}
                            onClick={onDelete}
                        ><Icon.Trash /> {t('deleteButtonLabel')}</Button>
                    </div>

                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="link"
                            disabled={deleteProductResult.isLoading}
                            onClick={() => navigate('/app/products')}
                        >{t('backButtonLabel')}</Button>
                    </div>
                </Col>
            </Row>
        }
        return view
    }, [
        productQuery.isLoading,
        productQuery.isSuccess,
        productQuery.data,
    ])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate(`/app/products`)}>{t('productsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('editProductTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {view}
        </Container>
    )
}

export default ProductEdit