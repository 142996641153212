import { skipToken } from "@reduxjs/toolkit/query"
import { Badge, Breadcrumb, Button, ButtonGroup, Col, Container, Dropdown, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import { ReactElement, useEffect, useMemo, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { IEvent, IListProduct, IMember, IShoppingList, Member } from "../../Types"
import EditProduct from "./ProductEdit"
import EventUserAvatars from "../EventUserAvatars"
import TitleButton from "../TitleButton"
import ProductListItem from "../ProductListItem"
import Loading from "../Loading"
import { useAuth0 } from "@auth0/auth0-react"
import { useGetShoppingListQuery, useUpdateShoppingListMutation } from "../../Store/ShoppingList/ShoppingList.service"
import { useListListProductsQuery } from "../../Store/ListProduct/ListProduct.service"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import UserAvatar from "../UserAvatar"
import { DateService } from "../../Services/Date"
import { useTranslation } from "react-i18next"
import ImportFreeText from "./import-free-text"

function SLists({ isShared }: { isShared?: boolean }) {

    const { eventId, listId } = useParams()

    let eventArgs: {
        eventId: string
        sharedListId?: string
    } | typeof skipToken = eventId ? { eventId } : skipToken
    if (eventId && listId) {
        eventArgs = {
            eventId,
        }
        if (isShared) {
            eventArgs = {
                eventId,
                sharedListId: listId,
            }
        }
    }
    const eventQuery = useGetEventQuery(eventArgs)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetShoppingListQuery(listArgs)
    const productsQuery = useListListProductsQuery(listArgs)

    const [productsComplete, setProductsComplete] = useState(false)
    const [showComplete, setShowComplete] = useState(false)

    const [editProduct, setEditProduct] = useState<IListProduct | null>(null)
    const [showEdit, setShowEdit] = useState(false)

    useEffect(() => {
        if (!productsQuery.isLoading && productsQuery.isSuccess && productsQuery.data) {
            if (editProduct) {
                for (const product of productsQuery.data) {
                    if (product._id === editProduct._id) {
                        setEditProduct({ ...product })
                        break
                    }
                }
            }
            let isComplete = true
            if (productsQuery.data.length) {
                for (const product of productsQuery.data) {
                    if (product.status !== "COMPLETE") {
                        isComplete = false
                        break
                    }
                }
            } else {
                isComplete = false
            }
            setProductsComplete(isComplete)
        }
    }, [
        productsQuery.isLoading,
        productsQuery.isSuccess,
        productsQuery.data,
    ])

    useEffect(() => {
        setShowComplete(false)
        if (!listQuery.isLoading && listQuery.isSuccess && listQuery.data) {
            if (!listQuery.data.isComplete && productsComplete) {
                setShowComplete(true)
            }
        }
    }, [listQuery.isLoading, listQuery.isSuccess, listQuery.data, productsComplete])

    const onEdit = (product: IListProduct) => {
        setEditProduct(product)
        setShowEdit(true)
    }

    return (
        <Container>
            <ShoppingListBreadcrumbs
                event={eventQuery.data}
                list={listQuery.data}
            />
            <ShoppingListTitle list={listQuery.data} />
            <ShoppingListSchedule scheduleAt={listQuery.data?.scheduleAt} />
            <ShoppingListAssignee assigneeUserId={listQuery.data?.assigneeUserId} />
            <ShoppingListMembers eventMembers={eventQuery.data?.members} listMembers={listQuery.data?.members} />
            <ShoppingListDescription list={listQuery.data} />
            <ProductAddButton list={listQuery.data} />
            <ShoppingListProducts
                editProduct={editProduct}
                onEdit={onEdit}
                handleClose={() => {
                    setEditProduct(null)
                    setShowEdit(false)
                }}
                showEdit={showEdit}
                products={productsQuery.data}
            />
            <ShoppingListFinalizeModal
                eventId={eventId}
                listId={listId}
                show={showComplete}
                onHide={() => setShowComplete(false)}
            />
        </Container>
    )
}

export default SLists

function ShoppingListFinalizeModal({ eventId, listId, show, onHide }: { eventId?: string, listId?: string, show: boolean, onHide: () => void }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()

    const markListComplete = async (isComplete: boolean) => {
        if (!eventId || !listId) {
            return
        }
        try {
            await updateShoppingList({ eventId, listId, isComplete }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    let view = null
    if (eventId && listId) {
        view = <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('finalizeShoppingListTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('finalizeShoppingListText')}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('closeLabel')}
                </Button>
                <Button
                    variant="primary"
                    disabled={updateShoppingListResult.isLoading}
                    onClick={() => markListComplete(true)}
                >
                    {updateShoppingListResult.isLoading ? <Loading /> : t('yesButtonLabel')}
                </Button>
            </Modal.Footer>
        </Modal>
    }

    return (
        view
    )
}

function ShoppingListMembers({ eventMembers, listMembers }: { eventMembers?: IMember[], listMembers?: IMember[] }) {

    const { t } = useTranslation()
    const members = useMemo(() => {
        if (!eventMembers?.length && !listMembers?.length) {
            return []
        }

        const allMembers: any = {}
        if (eventMembers?.length) {
            for (const member of eventMembers) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        if (listMembers?.length) {
            for (const member of listMembers) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        let allMembersValues: Member[] = []
        if (Object.keys(allMembers).length > 0) {
            allMembersValues = Object.values(allMembers)
        }
        const members = allMembersValues.map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [
        eventMembers,
        listMembers,
    ])

    let view = null
    if (members.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col><EventUserAvatars users={members.map(member => member)} /></Col>
        </Row>
    }

    return (
        view
    )
}

function ProductAddButton({ list }: { list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [show, setShow] = useState(false)

    let view = null
    if (list) {
        view = <Row>
            <Col>
                <div className="d-grid gap-2 mb-3">
                    <Dropdown as={ButtonGroup}>
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/app/events/${list.eventId}/lists/${list._id}/products-add`)}
                        ><Icon.Plus /> {t('addProductButtonLabel')}</Button>
                        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShow(true)}>{t('importFreeTextLabel')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <ImportFreeText
                    show={show}
                    onHide={() => setShow(false)}
                    list={list}
                />
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListTitle({ list }: { list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuth0();

    const { onClick, canEdit } = useMemo(() => {
        let canEdit = false
        let onClick = undefined
        if (user && list && user.sub === list.userId) {
            canEdit = true
            onClick = () => navigate(`/app/events/${list.eventId}/lists-edit/${list._id}?r=/app/events/${list.eventId}/lists/${list._id}`)
        }
        return { onClick, canEdit }
    }, [user, list, navigate])

    let view = null
    if (list) {
        view = <TitleButton
            title={<>{list.title}{list.isComplete ? <Badge bg="success" pill className="ms-1">{t('completeLabel')}</Badge> : null}</>}
            buttonText={canEdit ? <><Icon.Pencil /> {t('editButtonLabel')}</> : undefined}
            onClick={onClick}
        />
    }

    return (
        view
    )
}

function ShoppingListDescription({ list }: { list?: IShoppingList }) {

    let view = null
    if (list) {
        view = <Row className="mb-3">
            <Col className="small">
                {list.description}
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListBreadcrumbs({ event, list }: { event?: IEvent, list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user } = useAuth0();

    const { eventsLabel, eventsPath, onClick } = useMemo(() => {
        let onClick = undefined
        let eventsLabel = t('sharedEventsTitle')
        let eventsPath = '/app/shared-events'
        if (user && event) {
            if (user.sub === event.userId) {
                onClick = () => navigate(`${eventsPath}/${event._id}`)
                eventsLabel = t('eventsTitle')
                eventsPath = '/app/events'
            }
        } else {
            eventsLabel = t('eventsTitle')
            eventsPath = '/app/events'
        }
        return { eventsLabel, eventsPath, onClick }
    }, [user, event, navigate, t])

    let view = null
    if (event && list) {
        view = <Row>
            <Col>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(eventsPath)}>{eventsLabel}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={onClick} active={!onClick}>{event.title}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{list.title}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListProducts({
    editProduct,
    showEdit,
    products,
    handleClose,
    onEdit,
}: {
    editProduct: IListProduct | null
    showEdit: boolean
    products?: IListProduct[]
    handleClose: () => void
    onEdit: (product: IListProduct) => void
}) {

    const { t } = useTranslation()

    let view = <Row>
        <Col>
            {t('noProductsText')}
        </Col>
    </Row>
    if (products?.length) {
        view = <Row>
            <Col>
                {editProduct ? <EditProduct
                    show={showEdit}
                    handleClose={handleClose}
                    product={editProduct}
                /> : null}

                {products.map(product => <ProductListItem
                    product={product}
                    key={product._id}
                    onEdit={onEdit}
                />)}
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListAssignee({ assigneeUserId }: { assigneeUserId?: string }) {

    const { t } = useTranslation()
    const args = assigneeUserId ?? skipToken
    const userQuery = useGetUserQuery(args)

    const [view, setView] = useState<ReactElement | null>(null)

    useEffect(() => {
        if (userQuery.isSuccess && !userQuery.isLoading && userQuery.data) {
            const _view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('assigneeTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Person />
                    </OverlayTrigger>
                </Col>
                <Col><UserAvatar user={userQuery.data} showName={true} /></Col>
            </Row>
            setView(_view)
        }
    }, [
        userQuery.isSuccess,
        userQuery.isLoading,
        userQuery.data,
        t,
    ])

    return (
        view
    )
}

function ShoppingListSchedule({ scheduleAt }: { scheduleAt?: string | Date }) {

    let view = null
    if (scheduleAt) {
        view = <Row>
            <Col xs="auto"><Icon.Clock /></Col>
            <Col className="small">
                {DateService.formatDate(scheduleAt, 'LLLL')}
            </Col>
        </Row>
    }

    return (
        view
    )
}