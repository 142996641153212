import { Accordion, Button, Card, Col, ListGroup, ListGroupItem, Offcanvas, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IProduct, IShoppingList } from "../../Types"
import { useEffect, useState } from "react"
import { useAddListProductMutation, useImportFreeTextListProductsMutation } from "../../Store/ListProduct/ListProduct.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import * as Icon from 'react-bootstrap-icons'
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetStoreQuery } from "../../Store/Store/Store.service"
import ImportFreeTextForm from "./import-free-text-form"
import { useNavigate } from "react-router-dom"

function ImportFreeText({
    list,
    show,
    onHide,
}: {
    list: IShoppingList
    show: boolean
    onHide: () => void
}) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [activeKey, setActiveKey] = useState("0")
    const [formDisabled, setFormDisabled] = useState(false)
    const [importFreeText, importFreeTextResult] = useImportFreeTextListProductsMutation()

    const [addProduct, addProductResult] = useAddListProductMutation()

    const [productsList, setProductsList] = useState<{
        name: string
        qty?: number
        products?: IProduct[]
        shouldImport?: boolean
        selectedProductIndex?: number
    }[]>([])

    const submitText = async (text: string) => {
        try {
            const pList = await importFreeText({
                eventId: list.eventId,
                listId: list._id,
                text: text.trim(),
            }).unwrap()
            setProductsList(pList.map(p => {
                return {
                    ...p,
                    shouldImport: true,
                    selectedProductIndex: p.products?.length ? 0 : undefined,
                }
            }))
            setActiveKey('1')
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const markImport = (index: number, shouldImport: boolean | undefined) => {
        const p = []
        for (let i = 0; i < productsList.length; i++) {
            if (i === index) {
                p.push({ ...productsList[i], shouldImport: shouldImport ?? undefined })
            } else {
                p.push({ ...productsList[i] })
            }
        }
        setProductsList(p)
    }

    const setProductIndex = (index: number, productIndex: number) => {
        const p = []
        for (let i = 0; i < productsList.length; i++) {
            if (i === productIndex) {
                p.push({ ...productsList[i], selectedProductIndex: index })
            } else {
                p.push({ ...productsList[i] })
            }
        }
        setProductsList(p)
    }

    useEffect(() => {
        let v = false
        for (const p of productsList) {
            if (p.shouldImport) {
                v = true
                break
            }
        }
        if (v) {
            setFormDisabled(false)
        } else {
            setFormDisabled(true)
        }
    }, [productsList])

    const importSelected = async () => {
        if (productsList.length && list) {
            for (const p of productsList) {
                if (p.shouldImport) {
                    try {
                        if (p.products?.length && undefined !== p.selectedProductIndex && p.selectedProductIndex >= 0) {
                            const prod = p.products[p.selectedProductIndex]
                            await addProduct({
                                eventId: list.eventId,
                                listId: list._id,
                                title: prod.title,
                                description: prod.description,
                                qty: p.qty ?? 0,
                                productId: prod._id,
                            }).unwrap()
                        } else {
                            await addProduct({
                                eventId: list.eventId,
                                listId: list._id,
                                title: p.name,
                                qty: p.qty ?? 0,
                            }).unwrap()
                        }
                    } catch (err) {
                        dispatch(setMessage((err as ApiError).data.error))
                    }
                }
            }
            setProductsList([])
            onHide()
        }
    }

    return (
        <Offcanvas show={show} onHide={onHide}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('importFreeTextLabel')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Accordion defaultActiveKey="0" activeKey={activeKey} flush>
                    <Accordion.Item
                        eventKey={'0'}
                    >
                        <Accordion.Header onClick={() => setActiveKey('0')}>{t('freeTextShoppingListTitle')}</Accordion.Header>
                        <Accordion.Body>
                            <ImportFreeTextForm
                                disabled={importFreeTextResult.isLoading}
                                submitText={submitText}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    {productsList.length ? <Accordion.Item
                        eventKey={'1'}
                    >
                        <Accordion.Header onClick={() => setActiveKey('1')}>{t('freeTextImportResultTitle')}</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup className="mb-3">
                                {productsList.map((product, idx) => <Product
                                    key={idx}
                                    product={product}
                                    markImport={markImport}
                                    productIndex={idx}
                                    setProductIndex={setProductIndex}
                                    shouldImport={product.shouldImport ?? false}
                                />)}
                            </ListGroup>

                            <div className="d-grid gap-2">
                                <Button
                                    variant="primary"
                                    onClick={() => importSelected()}
                                    disabled={formDisabled || addProductResult.isLoading}
                                >{t('importButtonLabel')}</Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item> : null}
                </Accordion>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ImportFreeText

function Product({
    product,
    productIndex,
    shouldImport,
    markImport,
    setProductIndex,
}: {
    product: {
        name: string;
        qty?: number;
        products?: IProduct[]
    }
    productIndex: number
    shouldImport: boolean
    markImport: (index: number, shouldImport: boolean | undefined) => void
    setProductIndex: (index: number, productIndex: number) => void
}) {

    const [index, setIndex] = useState(0)

    useEffect(() => {
        setProductIndex(index, productIndex)
    }, [index])

    return (
        <ListGroupItem>
            {product.products?.length ? product.products.map((existingProduct, idx) => <ProductExistingProduct
                product={existingProduct}
                qty={product.qty}
                key={idx}
                show={index === idx}
                index={idx}
                shouldImport={shouldImport}
                total={product.products?.length ?? 0}
                setIndex={index => setIndex(index)}
                markImport={markImport}
            />) : null}
        </ListGroupItem>
    )
}

function ProductExistingProduct({
    product,
    qty,
    show,
    index,
    total,
    shouldImport,
    setIndex,
    markImport,
}: {
    product: IProduct
    qty?: number
    show: boolean
    index: number
    total: number
    shouldImport: boolean
    setIndex: (index: number) => void
    markImport: (index: number, shouldImport: boolean | undefined) => void
}) {

    const [importProduct, setImportProduct] = useState(shouldImport)

    const args = product.storeId ? { storeId: product.storeId } : skipToken
    const storeQuery = useGetStoreQuery(args)

    useEffect(() => {
        markImport(index, importProduct ?? undefined)
    }, [importProduct, index])

    let view = null
    if (show) {
        view = <Row>
            <Col xs="auto">
                {total > 1 ? <Row>
                    <Col><Icon.ChevronUp onClick={() => setIndex(index + 1 < total ? index + 1 : 0)} /></Col>
                </Row> : null}
                <Row>
                    <Col>{index + 1}/{total}</Col>
                </Row>
                {total > 1 ? <Row>
                    <Col><Icon.ChevronDown onClick={() => setIndex(index - 1 >= 0 ? index - 1 : total - 1)} /></Col>
                </Row> : null}
            </Col>
            {product.imageUrls?.length ? <Col xs="auto"><img src={product.imageUrls[0]} width={50} /></Col> : null}
            <Col xs="auto">{qty}</Col>
            <Col>
                <div>{product.title}</div>
                {storeQuery.data ? <div className="small">{storeQuery.data.name}</div> : null}
            </Col>
            <Col xs="auto">
                <Button
                    variant="link"
                    onClick={() => setImportProduct(!importProduct)}
                >{importProduct ? <Icon.Check className="text-success" /> : <Icon.Plus className="text-primary" />}</Button>
            </Col>
        </Row>
    }

    return (
        view
    )
}