import { Link, useNavigate } from "react-router-dom"
import { Badge, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { IEvent, IReceipt, IShoppingList, Member } from "../../Types"
import { useMemo } from "react"
import EventUserAvatars from "../EventUserAvatars"
import { DateService } from "../../Services/Date"
import UserAvatar from "../UserAvatar"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { ApiError } from "../../Services/BaseApi"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { useDeleteShoppingListMutation, useUpdateShoppingListMutation } from "../../Store/ShoppingList/ShoppingList.service"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { CurrencyService } from "../../Services/Currency"

function ShoppingListItem({ list, canEdit, isShared }: { list: IShoppingList, canEdit: boolean, isShared: boolean }) {

    const eventArgs = list.eventId ? { eventId: list.eventId } : skipToken
    const eventQuery = useGetEventQuery(eventArgs)

    const members = useMemo(() => {
        let members: Member[] = []
        if (list.members?.length) {
            members = list.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [list.members])

    return (
        <Card
            className="mb-3"
            border={list.isComplete ? "secondary" : undefined}
        >
            <ItemImage
                list={list}
                isShared={isShared}
            />
            <Card.Body>
                <ItemTitle
                    list={list}
                    isShared={isShared}
                />
                <ItemEvent
                    event={eventQuery.data}
                />
                <ItemMembers
                    members={members}
                />
                <ItemAssignee
                    assigneeUserId={list.assigneeUserId}
                />
                <ItemReceipts
                    receipts={list.receipts}
                />
                <ItemDescription
                    description={list.description}
                    isComplete={list.isComplete}
                />
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>
                        <ItemFooterComplete isComplete={list.isComplete} />
                    </Col>
                    <ItemFooterActions
                        list={list}
                        canEdit={canEdit}
                    />
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default ShoppingListItem

function ItemImage({ list, isShared }: { list?: IShoppingList, isShared?: boolean }) {

    const navigate = useNavigate()

    const view = useMemo(() => {
        let view = null
        if (list?.imageUrl) {
            view = <Card.Img
                onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${list.eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}
                src={list.imageUrl}
            />
        }
        return view
    }, [
        list?.imageUrl,
    ])

    return (
        view
    )
}

function ItemTitle({ list, isShared }: { list: IShoppingList, isShared: boolean }) {

    const navigate = useNavigate()

    const badgeBg = useMemo(() => {
        let bg = "warning"

        if (list.isComplete) {
            bg = "secondary"
        }
        if (moment(list.scheduleAt).isBefore()) {
            bg = "secondary"
        }

        return bg
    }, [
        list.isComplete,
        list.scheduleAt,
    ])

    return (
        <Card.Title
            onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${list.eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}
        >
            <Row>
                <Col className={list.isComplete ? 'text-muted' : undefined}>{list.title}</Col>
                {list.scheduleAt ? <Col xs="auto" className="text-end">
                    {badgeBg === "warning" ? <Badge
                        bg={badgeBg}
                        pill
                    >
                        DateService.formatDate(list.scheduleAt, "lll")
                        <Icon.Clock className="ms-1" />
                    </Badge> : null}
                    {badgeBg === "secondary" ? <Icon.Clock className="ms-1 text-muted" /> : null}
                </Col> : null}
            </Row>
        </Card.Title>
    )
}

function ItemEvent({ event }: { event?: IEvent }) {

    const view = useMemo(() => {
        let view = null
        if (event) {
            view = <Card.Text>
                <Link to={`/app/events/${event._id}`}>{event.title}</Link>
            </Card.Text>
        }
        return null
    }, [
        event,
    ])

    return (
        view
    )
}

function ItemMembers({ members }: { members: Member[] }) {

    const { t } = useTranslation()

    let view = null
    if (members) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col>
                <EventUserAvatars users={members} />
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ItemAssignee({ assigneeUserId }: { assigneeUserId?: string }) {

    const { t } = useTranslation()

    const args = assigneeUserId ?? skipToken
    const userQuery = useGetUserQuery(args)

    const view = useMemo(() => {
        let view = <Row className="mb-1">
            <Col xs="auto">
                <Icon.Person /> {t('unassignedLabel')}
            </Col>
        </Row>
        if (!userQuery.isLoading && userQuery.isSuccess && userQuery.data) {
            view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('assigneeTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Person />
                    </OverlayTrigger>
                </Col>
                <Col>
                    <UserAvatar
                        user={userQuery.data}
                    /> {userQuery.data.name}
                </Col>
            </Row>
        }
        return view
    }, [
        userQuery.isLoading,
        userQuery.isSuccess,
        userQuery.data,
        t,
    ])

    return (
        view
    )
}

function ItemReceipts({ receipts }: { receipts?: IReceipt[] }) {

    const { t } = useTranslation()

    const view = useMemo(() => {
        let view = null
        if (receipts?.length) {
            view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('receiptsLabel')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Cash />
                    </OverlayTrigger>
                </Col>
                <Col>
                    {receipts.map((receipt, idx) => {
                        let symbol = '$'
                        if (receipt.currencyCode) {
                            const currency = CurrencyService.getCurrency(receipt.currencyCode)
                            if (currency) {
                                symbol = currency.symbol
                            }
                        }
                        return (
                            <span key={idx}>{idx > 0 ? ', ' : ''}{symbol}{receipt.amount}</span>
                        )
                    })}
                </Col>
            </Row>
        }
        return view
    }, [
        receipts,
    ])

    return (
        view
    )
}

function ItemDescription({ description, isComplete }: { description?: string, isComplete?: boolean }) {

    const view = useMemo(() => {
        let view = null
        if (description) {
            view = <Row>
                <Col className={isComplete ? 'text-muted small' : 'small'}>{description}</Col>
            </Row>
        }
        return view
    }, [
        description,
        isComplete,
    ])

    return (
        view
    )
}

function ItemFooterComplete({ isComplete }: { isComplete?: boolean }) {

    const { t } = useTranslation()

    const view = useMemo(() => {
        let view = null
        if (isComplete) {
            view = <Badge bg="success" pill className="ms-1">{t('completeLabel')}</Badge>
        }
        return view
    }, [
        isComplete,
    ])

    return (
        view
    )
}

function ItemFooterActions({ list, canEdit }: { list: IShoppingList, canEdit: boolean }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [deleteShoppingList, deleteShoppingListResult] = useDeleteShoppingListMutation()

    const onComplete = async (list: IShoppingList) => {
        if (updateShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Mark shopping list "${list.title}" as complete?`)
            if (confirm) {
                await updateShoppingList({ eventId: list.eventId, listId: list._id, isComplete: true }).unwrap()
                dispatch(setMessage(t('shoppingListUpdatedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onIncomplete = async (list: IShoppingList) => {
        if (updateShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Mark shopping list "${list.title}" as incomplete?`)
            if (confirm) {
                await updateShoppingList({ eventId: list.eventId, listId: list._id, isComplete: false }).unwrap()
                dispatch(setMessage(t('shoppingListUpdatedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async (list: IShoppingList) => {
        if (deleteShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Delete shopping list "${list.title}"?`)
            if (confirm) {
                await deleteShoppingList({ eventId: list.eventId, listId: list._id }).unwrap()
                dispatch(setMessage(t('shoppingListRemovedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const view = useMemo(() => {
        let view = null
        if (canEdit) {
            view = <>
                {!list.isComplete ? <Col xs="auto" className="text-end">
                    <Icon.Check
                        className="text-success"
                        onClick={() => onComplete(list)}
                    />
                </Col> : null}
                {list.isComplete ? <Col xs="auto" className="text-end">
                    <Icon.X
                        onClick={() => onIncomplete(list)}
                    />
                </Col> : null}
                <Col xs="auto" className="text-end">
                    <Icon.Pencil
                        onClick={() => {
                            navigate(`/app/events/${list.eventId}/lists-edit/${list._id}?r=/app/events/${list.eventId}`)
                        }}
                    />
                </Col>
                <Col xs="auto" className="text-end">
                    <Icon.Trash
                        className="text-danger"
                        onClick={() => onDelete(list)}
                    />
                </Col>
            </>
        }
        return view
    }, [
        list.isComplete,
        list.eventId,
        navigate,
        canEdit,
    ])

    return (
        view
    )
}